import {
  IWorkflow,
  IWorkflowName,
  IWorkflowStageName,
} from '../context-providers/forms/forms-reducer';
import { fetchAPI } from './fetch-api';

const FormWorkflowUtil = {
  getWorkflows: async () => {
    const response = await fetchAPI<IWorkflow[]>({
      path: 'workflow/',
    });
    if (response.success) {
      return response.body;
    }
    return Promise.reject();
  },

  getWorkflow: async (id: number | string) => {
    const response = await fetchAPI<IWorkflow>({
      path: 'workflow/' + id,
    });
    if (response.success) {
      return response.body;
    }
    return Promise.reject();
  },

  getHardWorkflowDataQa(wfh: IWorkflow): string {
    if (!wfh.custom) {
      return `form-type-${wfh.id}`;
    }

    return 'form-type-custom';
  },

  getWorkflowStages: async (id: number | string) => {
    const response = await fetchAPI<IWorkflowStageName[]>({
      path: 'formtemplates/workflowstages/' + id,
    });
    if (response.success) {
      return response.body;
    }
    return Promise.reject();
  },

  getWorkflowsNonEnquiry: async () => {
    const response = await fetchAPI<IWorkflowName[]>({
      path: 'formtemplates/workflowsnonenquiry/',
    });
    if (response.success) {
      return response.body;
    }
    return Promise.reject();
  },
};

export default FormWorkflowUtil;
