import React from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { IconBin, IconDelete } from '../../icons';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { CreateCourseHeader } from '../ModalContent.style';
import { fetchAPI } from '../../../utils/fetch-api';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';

interface IDeleteWorkflow {
  workflow: IWorkflow;
  onSuccess?: () => void;
}

const DeleteWorkflow = ({ workflow, onSuccess }: IDeleteWorkflow) => {
  const closeModal = useCloseModal();

  const handleSubmit = async () => {
    const res = await fetchAPI({
      path: `Workflow/${workflow.id}`,
      method: 'DELETE',
      responseType: 'text',
    });
    if (res.success) {
      closeModal();
      onSuccess?.();
    }
  };

  return (
    <Stack stackMultiplier={2}>
      <CreateCourseHeader>
        <IconDelete width={25} height={25} />
        <h2>Delete Workflow</h2>
      </CreateCourseHeader>

      <p style={{ maxWidth: '39rem' }}>
        Deleting a form workflow will remove the workflow from the list, but any
        forms already associated with the workflow will remain unaffected.
      </p>

      <ButtonRowMobile>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="deleteData-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          variant="warn"
          iconLeft={<IconBin />}
          dataQa="deleteData-btn-submit"
        >
          Delete Workflow
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};

export default DeleteWorkflow;
