import styled from 'styled-components';
import { color } from '../../config';

export const TableContainer = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 8px;
  border: 2px solid ${color.grey.lightest};
  table-layout: fixed;

  tr {
    box-shadow: inset 0 2px ${color.grey.lightest};
  }

  thead {
    border: none;
    tr {
      background-color: ${color.secondary.base};
      box-shadow: none;
    }
  }

  th {
    border: none;
    text-align: left;
    position: relative;
  }

  td {
    padding: 1.5rem 1.5rem;
    text-align: left;
  }
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 1rem;
  }
`;

export const TableActions = styled.div`
  display: flex;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: red;
    cursor: pointer;
    border-radius: 999px;
    transition: background-color 0.2s;
    text-decoration: none;
    color: ${color.grey.dark};

    border: none;
    background: none;

    svg {
      width: 2.5rem;
    }

    &:not(:first-child) {
      margin-left: 1.2rem;
    }

    &:hover {
      background-color: ${color.grey.lightest};
    }
  }
`;

export const SingleLineCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
`;

export const SingleLineBoldCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  font-weight: bold;
`;
