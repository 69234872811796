import React from 'react';
import { IFormWorkflow } from '../../../utils/form-workflow-util';
import Radio from '../../atoms/form-fields/radio/Radio';
import { FormCard } from './FormCardWrapper.style';

export interface IFormCardProps {
  selectedWorkflow: IFormWorkflow | null;
  children: React.ReactNode;
  workflow: IFormWorkflow;
  onChange: () => void;
  dataQa: string;
}

const FormCardWrapper = ({
  selectedWorkflow: selectedWorkflow,
  children,
  dataQa,
  workflow: workflow,
  onChange,
}: IFormCardProps) => {
  return (
    <>
      <FormCard
        className={selectedWorkflow === workflow ? 'selected' : ''}
        onClick={() => {
          onChange();
        }}
      >
        <Radio
          id={workflow.id}
          name={workflow.id}
          checked={selectedWorkflow === workflow}
          data-qa={dataQa}
        />
        {children}
      </FormCard>
    </>
  );
};

export default FormCardWrapper;
