import styled from 'styled-components';

export const FormCard = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #8f959e;
  padding: 1.6rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
  user-select: none;

  &.selected {
    box-shadow: 0 0 0 1px #0055ff;
  }
`;

export const FormCardBorderWrapper = styled.div`
  margin: 4px;
`;

export const FormCardTitle = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  color: #2c333f;
  flex-grow: 1;
  margin-left: 0.8rem;
`;
