import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import AuthWrapper from '../../components/layout/AuthWrapper';
import ChooseFormType from '../../components/organisms/build-form/ChooseFormType';

const ChooseFormTypePage = () => {
  return (
    <AuthWrapper>
      <Head title="Choose form type" />
      <Layout
        section="templates"
        breadcrumbs={[
          { link: '/list-forms', label: 'Form Templates' },
          { label: 'Form Workflows' },
        ]}
      >
        <ChooseFormType />
      </Layout>
    </AuthWrapper>
  );
};

export default ChooseFormTypePage;
