import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import Button from '../../atoms/button/Button';
import { Flex } from '../../atoms/custom-elements/Flex.style';
import Stack from '../../atoms/stack/Stack';
import {
  IconArrowRight,
  IconChevronLeft,
  IconKebab,
  IconPlus,
  IconDelete,
} from '../../icons';
import { BackNavigation } from './ChooseFormType.style';
import FormCardWrapper from './FormCardWrapper';
import { FormCardTitle } from './FormCardWrapper.style';
import { FullWidthContainerInner } from '../../layout/Layout.style';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import FormWorkflowUtil from '../../../utils/form-workflow-util';
import { ButtonRow } from '../../atoms/button/Button.style';
import { MenuButton, MenuItem } from '@szhsin/react-menu';
import { TableActions } from '../../atoms/table/Table.style';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { KebabMenu } from '../../atoms/kebab-menu/KebabMenu.stye';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import { IconShow } from '../../icons/IconShow';
import DeleteWorkflow from '../../modals/forms/DeleteWorkflow';
import PreviewWorkflow from '../../modals/forms/PreviewWorkflow';

const isBrowser = typeof window !== 'undefined';

const ChooseFormType = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const [selectedWorkflow, setWorkflow] = useState<IWorkflow | null>(null);

  const [workflowHeaders, setWorkflowHeaders] = useState<IWorkflow[]>([]);

  const showModal = useShowModal();
  const getWorkflows = (): void => {
    FormWorkflowUtil.getWorkflows().then((response) => {
      setWorkflow(null);
      setWorkflowHeaders(response);
    });
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  const handleTemplateSelection = () => {
    navigate(`/build-form/?workflowId=${selectedWorkflow?.id}`);
  };

  return (
    <>
      <BackNavigation>
        <Link to="/list-forms" title="Back to form templates">
          <IconChevronLeft />
          Back to form templates
          <span className="sr-only">Back to form templates</span>
        </Link>
      </BackNavigation>
      <>
        <FullWidthContainerInner>
          <Stack stackMultiplier={2}>
            <GridFlex
              gutters={false}
              align="flex-start"
              justify="space-between"
            >
              <GridFlexItem gutters={false} small={6}>
                <Stack stackMultiplier={1}>
                  <h1>Select Form Workflow</h1>
                  <h4>
                    This will define who is completing the form, and in what
                    order.
                  </h4>
                </Stack>
              </GridFlexItem>
              <ButtonRow>
                <Button
                  iconLeft={<IconPlus responsive />}
                  onClick={() => navigate(`/build-workflow/`)}
                  variant="secondary"
                  dataQa="form-type-btn-create-workflow"
                >
                  Create form workflow
                </Button>
                <Button
                  iconRight={<IconArrowRight responsive />}
                  onClick={() => handleTemplateSelection()}
                  disabled={selectedWorkflow == null}
                  dataQa="form-type-btn-next"
                >
                  Continue to create form
                </Button>
              </ButtonRow>
            </GridFlex>
            <br />
            <br />
          </Stack>
          <Flex gap={32} direction="column" justifyContent="center">
            {workflowHeaders &&
              workflowHeaders.map((workflow) => (
                <FormCardWrapper
                  key={workflow.id}
                  workflow={workflow}
                  dataQa={FormWorkflowUtil.getHardWorkflowDataQa(workflow)}
                  onChange={() => {
                    ReactGA.event({
                      category: 'Button',
                      action: `Choose form type - ${workflow.workflowName}`,
                    });
                    setWorkflow(workflow);
                  }}
                  selectedWorkflow={selectedWorkflow}
                >
                  <FormCardTitle>{workflow.workflowName}</FormCardTitle>
                  <TableActions>
                    <KebabMenu
                      menuButton={
                        <MenuButton
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <IconKebab responsive />
                        </MenuButton>
                      }
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.syntheticEvent.stopPropagation();
                          showModal(PreviewWorkflow, { workflow });
                        }}
                      >
                        <IconShow width={16} />
                        &nbsp;&nbsp;View
                      </MenuItem>
                      {workflow.custom && !workflow.isActive && (
                        <MenuItem
                          onClick={() => {
                            showModal(DeleteWorkflow, {
                              workflow,
                              onSuccess: () => getWorkflows(),
                            });
                          }}
                        >
                          <IconDelete width={16} />
                          &nbsp;&nbsp;Delete
                        </MenuItem>
                      )}
                    </KebabMenu>
                  </TableActions>
                </FormCardWrapper>
              ))}
          </Flex>
        </FullWidthContainerInner>
      </>
    </>
  );
};

export default ChooseFormType;
