import styled from 'styled-components';

export const Head = styled.div`
  text-align: center;
  padding: 4rem 0;

  p {
    font-size: 2rem;
    color: rgb(93, 102, 115);
    text-align: center;
    margin-top: 2.8rem;
    font-weight: 400;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 9rem;
  text-align: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 4rem;
`;

export const CardWrapper = styled.div`
  text-align: center;
  margin: 0 16px;
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BackNavigation = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 24px;
  display: flex;

  & a {
    color: #2c333f;
    display: flex;
    text-decoration: none;
  }
`;
