import React from 'react';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import IconButton from '../../atoms/icon-button/IconButton';
import Stack from '../../atoms/stack/Stack';
import { IconClose } from '../../icons';
import BuildWorkflowItem from '../../organisms/build-workflow/BuildWorkflowItem';
import { DialogHeader } from '../../layout/Layout.style';
import { Box } from '../../atoms/custom-elements/Box.style';

interface IPreviewWorkflowProps {
  workflow: IWorkflow;
}

const PreviewWorkflow = ({ workflow }: IPreviewWorkflowProps) => {
  const closeModal = useCloseModal();

  return (
    <Box style={{ minWidth: '45rem' }}>
      <Stack stackMultiplier={2}>
        <DialogHeader>
          <h2>{workflow.workflowName}</h2>
          <IconButton
            variant="black"
            onClick={() => closeModal()}
            icon={<IconClose responsive />}
          ></IconButton>
        </DialogHeader>
        <Stack stackMultiplier={1}>
          {workflow.stages.map((stage, i) => (
            <BuildWorkflowItem
              controls={false}
              showIcons={true}
              key={`stage-` + i}
              index={i}
              stage={stage}
            ></BuildWorkflowItem>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PreviewWorkflow;
